.powerbi-report {
  height: 95vh;
  overflow: hidden;
}

.powerbi-report iframe {
  border: none;
}

.powerbi-report .explorationNavigationContent {
  display: none;
}