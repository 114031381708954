/* TODO: update the styles to respective files later. */
html, body, .App, #root {
  height: 100%;
}

.frame-container {
  height: calc(100% - 54px);
}

/* Header style */
.header {
  height: 44px;
  background: #009df0!important;
  padding: 5px 30px;
  display: flex;
  align-items: center;
}

.header span.logo {
  color: #FFF;
  font-weight: bold;
  font-size: 25px;
}
