.side-nav {
  float: left;
  padding: 0;
  margin: 0;
  height: 95vh;
  border-right: 2px solid #009df0;
  width: 270px;
}

.side-nav li {
  padding: 10px 20px;
  cursor: pointer;
}

.side-nav li:hover{
  background: #009df0;
  color: #FFF;
}